* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}

h1 {
	font-size: 2rem;
	margin-top: 2.5rem;
	font-weight: 700;
}

form {
	width: 100%;
	color: #7e7e7e;
}

label {
	font-size: 1.2rem;
	font-weight: 500;
}

input {
	margin-top: 0.5rem;
	border: 1px solid #ccc;
	border-radius: 10px;
}

.buttons {
	display: flex;
	float: right;
	margin-top: 2rem;
	margin-right: 10px;
}

.submit {
	background-color: #3d348b;
	color: white;
	border-radius: 10px;
	width: 165px;
	height: 48px;
}

.cancel {
	float: left;
	background-color: black;
	color: white;
	margin-right: 2rem;
	border-radius: 10px;
	width: 165px;
	height: 48px;
}

.custom-dropdown {
	position: relative;
	display: inline-block;
	width: 100%;
}

.custom-dropdown select {
	appearance: none;
	/* Remove default arrow */
	-webkit-appearance: none;
	/* Remove default arrow for Safari */
	-moz-appearance: none;
	/* Remove default arrow for Firefox */
	background: none;
	width: 100%;
	padding: 0.5em;
	border: 1px solid #D1D1D1;
	border-radius: 10px;
	font-size: 16px;
}

.custom-dropdown::after {
	content: '⏷';
	/* Unicode character for down arrow */
	font-size: 20px;
	color: #333;
	position: absolute;
	right: 3%;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
	/* Ensure the arrow is not clickable */
}