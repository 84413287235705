* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}

.subjects {
	display: flex;
	float: right;
	font-size: 1.3em;
	border-radius: 15px;
	margin-left: 50%;
	padding: 15px 40px;
}

table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 30px;
	align-items: center;
	justify-content: center;
}

td {
	padding: 0 20px;
	color: black;
	font-size: 1em;
}

th {
	padding: 20px;
	color: black;
	font-size: 1.2em;
}

tr {
	padding: 10px;
}

img {
	padding: 10px;
}