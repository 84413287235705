* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}

.inquiries {
	display: flex;
	margin-top: 5%;
	justify-content: center;
	align-items: center;
	text-align: center;
}

table {
	width: 90%;
	border-collapse: collapse;
	margin-bottom: 30px;
}

th {
	padding: 20px;
	color: #495d72;
	font-size: 1em;
}

img {
	padding: 10px;
}