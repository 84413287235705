* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}

h1 {
	font-size: 2rem;
	margin-top: 2.5rem;
	font-weight: 700;
}

form {
	width: 100%;
	color: #7e7e7e;
}

label {
	font-size: 1.2rem;
	font-weight: 500;
}

input {
	margin-top: 0.5rem;
	border: 1px solid #ccc;
	border-radius: 10px;
}

.buttons {
	display: flex;
	float: right;
	margin-top: 2rem;
	border-radius: 30px;
}