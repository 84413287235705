.upload-box {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

}

.upload-input {
  display: none;
}

.upload-label {
  cursor: pointer;
  display: inline-block;
  margin-right: auto;
  margin-bottom: 0;
}

.pdf-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 40px;
  color: #d9534f;
  font-weight: bold;
}