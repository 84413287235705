* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}

.result {
	margin-top: 5%;
	justify-content: center;
}

.rank {
	font-size: 1.5em;
	border-radius: 15px;
	margin-left: 50%;
}

label {
	font-size: 1em;
}

select {
	font-size: 1.4em;
	margin: 5px;
	padding: 0 15px;
	border-radius: 10px;
}

.result h3 {
	font-size: 1.5em;
	margin-top: 20px;
	font-weight: bold;
}

h4 {
	font-size: 1em;
	margin-top: 20px;
	font-weight: bold;
}

table {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 30px;
}

th {
	padding: 20px;
	color: black;
	font-size: 1.2em;
}

tr {
	padding: 20px;
}

img {
	padding: 10px;
}