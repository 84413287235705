* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.login {
	justify-content: center;
	align-items: center;
	height: 80vh;
	margin-top: 10%;
}

h1 {
	margin-bottom: 30px;
	color: #133f73;
}

.security {
	font-size: 1rem;
}

.login-in {
	width: 100%;
	margin-top: 5%;
	background-color: #133f73;
	color: white;
	padding: 20px;
}