.delete-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: all 0.3s;
}

.delete-popup-content {
  background-color: white;
  padding: 50px 0px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: 35%;
}

.delete-popup-content h3 {
  margin: 0;
  text-align: center;
  font-weight: bold;
}

.delete-popup-buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 30px;
}

.delete-popup-content button {
  padding: 10px 15px;
  border: none;
  background-color: #f44336;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.delete-btn {
  width: 35%;
  font-size: 1.2rem;
}

.delete-popup-content button:hover {
  background-color: #d32f2f;
}

.delete-popup-content button:last-child {
  background-color: #4caf50;
}

.delete-popup-content button:last-child:hover {
  background-color: #388e3c;
}