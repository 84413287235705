.image-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  height: 200px;
}

.upload-placeholder {
  text-align: center;
  color: #666;
}

.upload-icon {
  font-size: 40px;
  color: #ccc;
}

.select-image {
  color: #007BFF;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.uploaded-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.remove-image {
  position: absolute;
  top: 0px;
  right: -10px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}


.remove-image:active {
  border: none;
  outline: none;
}