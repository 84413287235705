* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}

h1 {
	font-size: 2rem;
	margin-top: 2rem;
	font-weight: 700;
}

form {
	width: 100%;
}